import { useEffect, useRef, useState } from 'react';
import { updateFilters } from 'app-components/Table/tableUtils';
import { deepCopy, isValid } from 'components/shared/componentUtils';
import { text as t } from 'shared/text';
import useInputs from 'components/hooks/Inputs/useInputs';
import Checkbox from 'app-components/Checkbox/Checkbox';
import ContextMenu from 'app-components/ContextMenu/ContextMenu';
import Input from 'components/Input/Input';

const TableSearchBar = ({layout, currentLayout, setCurrentLayout, tableStates, setTableStates, searchBarContent, editable}) => {
    const { inputValues, updateInput } = useInputs(); 
    const [ openSettings, setOpenSettings ] = useState();
    const [ columnSelectors, setColumnSelectors ] = useState();
    const id = layout.id;
    const fieldId = id + '-table-search-input';
    const inputId = layout.id +'-search-bar-input';  
    const columnStates = useRef()  
    
    useEffect(()=> {
        const searchData = tableStates?.[id]?.filters?.inputValue
        if (searchData) {
            updateInput(fieldId, searchData.value);
        } else {
            updateInput(fieldId, '');
        }
        createColumnSelectors();
    }, [])

    useEffect(()=> {
        if (!isValid(inputValues[fieldId])) {return}
        updateSeachInput(inputValues[fieldId]);
    }, [inputValues])

    const updateSeachInput = (value) => {
        if (!tableStates?.[id]) {return};
        const inputValue = {
            name: inputId,
            value: value
        }
        const newStates = deepCopy(tableStates);
        newStates[id].filters.inputValue = inputValue;
        updateFilters(newStates, setTableStates);
    }

    const toggleSettings = () => {
        if (openSettings) {
            columnStates.current = null;
            setColumnSelectors(null)
        } else {
            createColumnSelectors();
        }
        setOpenSettings(!openSettings);
    }

    const createColumnSelectors = () => {
        const headers = layout.tableHeaders
        const states = {}
        const checkboxes = [];
        const list = Object.keys(layout.tableHeaders);
        const savedHeaders = tableStates[id]?.layout?.tableHeaders;
        const length = list.length;
        for (let i=0; i<length; i++) {
            const key = list[i];
            const stateOn = savedHeaders ? savedHeaders.hasOwnProperty(key) : true;
            let label = headers[key];
            if (label && label.includes('<br/>')) {
                label = label.replace('<br/>', ' ');
            }
            states[key] = stateOn;
            checkboxes.push(<Checkbox
                classes={key === 'checkbox' ? 'hide' : ''}
                key={i}
                type='checkbox'
                label={label}
                defaultVal={stateOn}
                callback={() =>{onCheckboxClick(key)}}/>)
        }
        columnStates.current = states;
        setColumnSelectors(checkboxes);
    }

    const onCheckboxClick = (prop) => {
        const states = deepCopy(columnStates.current);
        states[prop] = !states[prop];
        columnStates.current = states;
    }

    const onUpdateColumns = () => {
        const selected = columnStates.current
        const list = Object.keys(selected);
        const length = list.length;
        const headers = layout.tableHeaders;
        const newHeaders = {}
        for (let i=0; i<length; i++) {
            const key = list[i];
            if (selected[key]) {newHeaders[key] = headers[key]}
        }
        const layoutCopy = deepCopy(currentLayout);
        const statesCopy = deepCopy(tableStates);
        layoutCopy.tableHeaders=newHeaders;
        statesCopy[id].layout = layoutCopy;
        setCurrentLayout(layoutCopy);
        setTableStates(statesCopy);
        toggleSettings();
    }

    return (
        <div className={`table-search-bar ${layout.hideSearch? 'hide' : ''}`}>
            <div className='table-search-bar-content grid'>
                {searchBarContent}
            </div>
            <div className='table-search-right'>
                {(layout.contextMenu && editable) &&  <ContextMenu layout={layout}/>}
                {layout.hideInput ? '' : <Input
                    id={fieldId}
                    classes='search-bar-input'
                    type='searchInput'
                    defaultVal={tableStates?.[id]?.filters?.inputValue?.value}
                    label={t.inputSearch + ':'}
                    hideErrors={true}
                />}
            </div>
        </div>
    )
}

export default TableSearchBar;
